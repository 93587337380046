import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  CoffreFortClientResource,
  CollecteResource,
  CollecteTourneeResource,
  SituationCoffreResource,
} from "types/caisse.type";
import { FilterProps, ListPaginated } from "types/list.type";

type caisseState = {
  collectes: ListPaginated<CollecteResource>;
  collecteFilter: FilterProps;

  tournees: ListPaginated<CollecteTourneeResource>;
  tourneeFilter: FilterProps;

  coffreFortClient: ListPaginated<CoffreFortClientResource>;
  coffreFortClientFilter: FilterProps;

  mouvements: ListPaginated<SituationCoffreResource>;
  mouvementsFilter: FilterProps;
};

const initialState: caisseState = {
  collectes: {
    data: [],
    total: 0,
    totalPage: 0,
    page: 1,
    totalInDatabase: 0,
  },
  collecteFilter: {
    page: 1,
    perpage: 10,
  },

  tournees: {
    data: [],
    total: 0,
    totalPage: 0,
    page: 1,
    totalInDatabase: 0,
  },
  tourneeFilter: {
    page: 1,
    perpage: 10,
  },

  coffreFortClient: {
    data: [],
    total: 0,
    totalPage: 0,
    page: 1,
  },
  coffreFortClientFilter: {
    page: 1,
    perpage: 10,
  },

  mouvements: {
    data: [],
    total: 0,
    totalPage: 0,
    page: 1,
  },
  mouvementsFilter: {
    page: 1,
    perpage: 10,
  },
};

const collecteSlice = createSlice({
  name: "collecte",
  initialState,
  reducers: {
    setCollectes(
      state,
      action: PayloadAction<ListPaginated<CollecteResource>>
    ) {
      state.collectes = action.payload;
    },
    setCollecteFilter(state, { payload }: PayloadAction<Record<string, any>>) {
      state.collecteFilter = payload;
    },

    setCollectesTournees(
      state,
      action: PayloadAction<ListPaginated<CollecteTourneeResource>>
    ) {
      state.tournees = action.payload;
    },
    setCollecteTourneeFilter(
      state,
      { payload }: PayloadAction<Record<string, any>>
    ) {
      state.tourneeFilter = payload;
    },

    setCoffreFortsClients(
      state,
      action: PayloadAction<ListPaginated<CoffreFortClientResource>>
    ) {
      state.coffreFortClient = action.payload;
    },
    setCoffreFortClientFilter(
      state,
      { payload }: PayloadAction<Record<string, any>>
    ) {
      state.coffreFortClientFilter = payload;
    },

    setMouvementsCoffres(
      state,
      action: PayloadAction<ListPaginated<SituationCoffreResource>>
    ) {
      state.mouvements = action.payload;
    },
    setMouvementCoffreFilter(
      state,
      { payload }: PayloadAction<Record<string, any>>
    ) {
      state.mouvementsFilter = payload;
    },
  },
});

export default collecteSlice.reducer;

export const {
  setCollecteFilter,
  setCollectes,
  setCollectesTournees,
  setCollecteTourneeFilter,
  setCoffreFortClientFilter,
  setCoffreFortsClients,
  setMouvementCoffreFilter,
  setMouvementsCoffres,
} = collecteSlice.actions;

const getCaisseReducer = (state: any): caisseState => state.caisse;

export const selectCollectes = createSelector(
  getCaisseReducer,
  ({ collectes }) => collectes
);

export const selectCollecteFilter = createSelector(
  getCaisseReducer,
  ({ collecteFilter }) => collecteFilter
);

export const selectCollectesTournees = createSelector(
  getCaisseReducer,
  ({ tournees }) => tournees
);

export const selectCollecteTourneeFilter = createSelector(
  getCaisseReducer,
  ({ tourneeFilter }) => tourneeFilter
);

export const selectCoffreFortsClients = createSelector(
  getCaisseReducer,
  ({ coffreFortClient }) => coffreFortClient
);

export const selectCoffreFortClientFilter = createSelector(
  getCaisseReducer,
  ({ coffreFortClientFilter }) => coffreFortClientFilter
);

export const selectMouvementsCoffres = createSelector(
  getCaisseReducer,
  ({ mouvements }) => mouvements
);

export const selectMouvementFilter = createSelector(
  getCaisseReducer,
  ({ mouvementsFilter }) => mouvementsFilter
);
